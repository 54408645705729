<template>
  <div class="service">
       <h5 class="title">stochastic probability fairness</h5>
       <div class="text_msg">Our company adheres to the principle of fairness, justice and openness to provide users with Steam game virtual goods unboxing service. Chest drops are determined using numpy.randon.choice, a random function provided by Numpy. This means that we have no way of knowing what the next drop will be until every time we open the box.</div>
       <div class="text_msg">The unboxing probability is passed as an argument to the numpy.randon.choice function, which generates a pseudo-random number using the Mason rotation algorithm in computer pseudo-randoms to determine the next drop. Although theoretically each drop is deterministic, because the algorithm generates pseudo-random numbers with an extremely long spawn period (reaching 219937−1), this method is statistically consistent with the box drop distribution. The random number seed dropped each time is based on the SHA256 algorithm to calculate the period hash value of the current server UNIX system timestamp, and then numpy.randon.choice will use this seed to make a drop sample, which ensures that the user drops completely randomly every time he opens the box, and the background is not controllable.</div>
       <div class="text_msg" style='color:#00ae56;'>in short, our company will not and can not control the user's unboxing rate in the background. for a 50% drop rate (refer to the front and back of the coin toss), we cannot guarantee that the result distribution will always be: positive, negative, positive, negative, positive, negative, positive, negative, positive, negative, positive, negative. the 50% drop rate does not mean that 2 times must be opened once, and 10 times must be opened 5 times; in the same way, the 10% drop rate is not equal to 1 time out of 10 times, it may be 20 out of 1, and it may also be 3 out of 1. unpacking is risky, and consumption needs to be rational. have fun, don't mess with your heart.</div>
       <div class="text_msg"></div>
       <div class="text_msg"></div>
       <div class="text_msg"></div>
       <div class="text_msg"></div>
       <div class="text_msg"></div>
       <div class="text_msg"></div>
       <div class="text_msg"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>